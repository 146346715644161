// @ts-strict-ignore
import createPlatformClient from '@livechat/platform-client';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { getConfig } from 'helpers/config';
import { trimObject } from 'helpers/object';
import { type IServer } from 'interfaces/server';
import { getRegion } from 'services/auth/auth-storage-manager';
import { DEFAULT_RECONNECT_RETRY_COUNT } from 'services/connectivity/reconnector/events';
import { getReconnector } from 'services/connectivity/reconnector/service';
import { login } from 'services/server/login';

import type {
  PlatformClient,
  CreatePlatformClientOptions,
  Request,
  PlatformEvent,
  PlatformEventHandler,
  OriginalPlatformEventHandler,
} from './types';

const log = debug(DebugLogsNamespace.AppServerConnection);

export class ServerAccessPlatform implements IServer {
  platformClient: PlatformClient;

  private accessToken: string | null = null;
  private region: string | null = null;
  private compressionEnabled = true;

  private handleConnectBinded: (() => void) | null = null;
  private handleDisconnectBinded: (() => void) | null = null;
  private handleConnectionUnstableBinded: (() => void) | null = null;

  private handlers: { event: PlatformEvent; handler: PlatformEventHandler }[] = [];

  constructor(compressionEnabled: boolean) {
    this.reinitialize(compressionEnabled);
  }

  on(event: PlatformEvent, handler: PlatformEventHandler): void {
    this.platformClient.on(event, handler as OriginalPlatformEventHandler);
    this.handlers.push({ event, handler });
  }

  off(event: PlatformEvent, handler?: PlatformEventHandler): void {
    this.platformClient.off(event, handler as OriginalPlatformEventHandler);
    this.handlers = this.handlers.filter((h) => !(h.event === event && h.handler === handler));
  }

  connect(accessToken: string): void {
    this.accessToken = accessToken;

    if (getRegion() !== this.region) {
      this.reinitialize(this.compressionEnabled);
    }

    this.platformClient.connect();
  }

  disconnect(): void {
    this.platformClient.disconnect();
  }

  /**
   * Always login on 'connect' event.
   * The 'connect' event may be result of manual connect but also unpredictable auto reconnects
   * which may happen during network issues or backend restart.
   * The backend restart does not send any warning push, it just closes the connection
   * making the library to reconnect to a different backend instance.
   * All new connects require to login again. Otherwise a new connection will be considered as failure
   * and agent will be disconnected after a timeout.
   */
  handleConnect(): void {
    log('Socket has connected.');

    if (!this.accessToken) {
      throw new Error('Access token not provided, cannot login.');
    }

    void login(this, this.accessToken);
  }

  /**
   * The connection was lost and auto reconnect did not work.
   * The reason might be a backend restart or network issues.
   * Reconnect again. We don't have to check internet availability
   * because the library does not send 'disconnect' event when being offline.
   */
  handleDisconnect(): void {
    log('Socket has disconnected.');

    const reconnector = getReconnector();
    reconnector.loginFailed();
    void reconnector.reconnect({ reason: 'socket_disconnected', maxAttempts: DEFAULT_RECONNECT_RETRY_COUNT });
  }

  /**
   * The connection is unstable due to network issues (we're offline).
   * Reconnection should not be performed because getting back online
   * should restore the connection or emit `disconnect` event handled by different handler.
   */
  handleConnectionUnstable(): void {
    log('Socket connection is unstable.');
  }

  send(message: Request): void {
    this.platformClient.emit(message);
  }

  isOpen(): boolean {
    return this.platformClient.getReadyState() === 1;
  }

  reinitialize(compressionEnabled: boolean): void {
    const region = getRegion();

    if (!region) {
      throw new Error('Region not specified. Could not create platform client.');
    }

    this.region = region;
    this.compressionEnabled = compressionEnabled;

    const config = getConfig();
    const endpoint = config.websocketApiUrl;

    const options: CreatePlatformClientOptions = {
      query: trimObject({
        compress: compressionEnabled ? compressionEnabled.toString() : undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'x-region': region,
      }),
    };

    if (this.platformClient) {
      log('Disconnecting from previous platform client');

      this.platformClient.off('connect', this.handleConnectBinded);
      this.platformClient.off('disconnect', this.handleDisconnectBinded);
      this.platformClient.off('connection_unstable', this.handleConnectionUnstableBinded);

      this.handlers.forEach(({ event, handler }) => {
        this.platformClient.off(event, handler as OriginalPlatformEventHandler);
      });
    }

    log('Creating platform client', { endpoint, options, compressionEnabled });

    this.handleConnectBinded = this.handleConnect.bind(this);
    this.handleDisconnectBinded = this.handleDisconnect.bind(this);
    this.handleConnectionUnstableBinded = this.handleConnectionUnstable.bind(this);

    this.platformClient = createPlatformClient(endpoint, options);

    this.platformClient.on('connect', this.handleConnectBinded);
    this.platformClient.on('disconnect', this.handleDisconnectBinded);
    this.platformClient.on('connection_unstable', this.handleConnectionUnstableBinded);

    this.handlers.forEach(({ event, handler }) => {
      this.platformClient.on(event, handler as OriginalPlatformEventHandler);
    });
  }
}
