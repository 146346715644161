import { css, keyframes } from '@emotion/css';
import { DesignToken } from '@livechat/design-system-react-components';

import { SMALL_SCREEN_BREAKPOINT } from 'constants/responsive-breakpoint';

const shine = keyframes`
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
`;

export const skeletonLine = css`
  background: var(${DesignToken.SurfaceSecondaryDisabled});
  width: 35%;
  height: 12px;
  border-radius: 6px;
  margin-bottom: 12px;
`;

export const loadingAnimation = css`
  background-image: linear-gradient(90deg, rgba(231, 231, 231, 0) 0.08%, #e7e7e7 50.5%, rgba(231, 231, 231, 0) 99.89%);
  animation: ${shine} 2s ease infinite;
  background-size: 500% 100%;
`;

export const skeletonLineWide = css`
  width: 55%;
`;

export const skeletonLineTall = css`
  height: 100%;
`;

export const skeletonLineRemovedMargin = css`
  margin-bottom: 0;
`;

export const emptyBox = css`
  ${loadingAnimation};
  height: 10px;
  border-radius: 5px;
`;

export const avatarDummy = css`
  background: var(${DesignToken.SurfaceSecondaryDisabled});
  border-radius: 50%;
  width: 44px;
  height: 44px;
  margin-right: 16px;
  flex-shrink: 0;
`;

export const listElement = css`
  padding: 19px 24px;
  display: flex;
`;

export const textDummy = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const wrapper = css`
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
`;

export const loaderWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const homeScreenWrapper = css`
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const homeScreenContent = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const homeScreenReportsWrapper = css`
  ${homeScreenWrapper};
  justify-content: space-between;
`;

export const homeScreenReportsContent = css`
  ${homeScreenContent};
  max-width: 464px;
  width: calc(50% - 20px);
`;

export const loader = css`
  ${loadingAnimation};
  width: 100%;
  height: 171px;
  margin-bottom: 16px;
`;

export const listItem = css`
  width: 100%;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const evenContentStyle = css`
  & > :nth-child(1) {
    width: 50%;
  }
  & > :nth-child(2) {
    width: 30%;
  }
  & > :nth-child(3) {
    width: 45%;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  & > :nth-child(4) {
    width: 90%;
  }
`;

export const oddContentStyle = css`
  & > :nth-child(1) {
    width: 40%;
  }
  & > :nth-child(2) {
    width: 20%;
  }
  & > :nth-child(3) {
    width: 60%;
    margin-bottom: 20px;
    margin-top: 16px;
  }
  & > :nth-child(4) {
    width: 90%;
  }
`;

export const compactModeSkeletonStyle = css`
  & > :nth-child(3) {
    margin-bottom: 0;
  }
`;

export const detailedModeSkeletonStyle = css`
  & > :nth-child(5) {
    margin-top: 16px;
    margin-bottom: 6px;
    width: 60%;
  }
`;

export const listItemContent = css`
  padding: 12px 6px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #dde2e6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > div,
  & > p {
    ${emptyBox};
  }
`;

export const appSkeletonWrapper = css`
  width: 100%;
  height: 100%;
  background: var(${DesignToken.Background01});
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${SMALL_SCREEN_BREAKPOINT}px) {
    height: calc(100vh - 56px);
    order: -1;
  }
`;

export const appSkeletonLoaderWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const skeletonContainer = css`
  position: relative;
  padding-bottom: 40px;
`;
