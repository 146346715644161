import * as Sentry from '@sentry/browser';
import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { ServerError } from 'constants/server-error';
import { redirectToAccounts, redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import {
  isLicenseBlockedInCRM,
  isLicenseExpired,
  isRequestSuspended,
  isRequestUnauthorized,
  isRequestWaitingForApproval,
  type ApiError,
} from 'helpers/request-decoder';
import type { MessageErrorPayload } from 'interfaces/incoming-message';
import type { SignoutReason } from 'interfaces/session';
import { ApiErrorMessagePart } from 'services/api/constants';
import {
  ConfigurationApiErrorMessage,
  type ConfigurationApiNormalizedError,
} from 'services/connectivity/configuration-api/types';
import { type GlobalAccountsApiNormalizedError } from 'services/connectivity/global-accounts-api/types';
import { getReconnector } from 'services/connectivity/reconnector/service';

import { handleServerError } from './handle-server-error';

const log = debug(DebugLogsNamespace.AppServerConnection);

export function mapConfigurationApiErrorToServerError(error: ConfigurationApiNormalizedError): ServerError | null {
  switch (error.message as ConfigurationApiErrorMessage) {
    case ConfigurationApiErrorMessage.AwaitingApproval:
      return ServerError.WaitingForApproval;
    case ConfigurationApiErrorMessage.UserNotActive:
      return ServerError.LicenseBlockedInCRM;
    case ConfigurationApiErrorMessage.AccountSuspended:
      return ServerError.AccountSuspended;
    default:
      break;
  }

  switch (error.type) {
    case 'too_many_requests':
      return ServerError.TooManyRequests;
    case 'authentication':
    case 'authorization':
      return ServerError.InvalidSession;
    case 'license_expired':
      return ServerError.LicenseExpired;
    case 'misdirected_request':
      return ServerError.MisdirectedRequest;
  }

  return null;
}

function mapAccountsApiErrorToServerError(error: GlobalAccountsApiNormalizedError): ServerError | null {
  switch (error.error) {
    case 'unauthorized_client':
    case 'unauthorized':
      return ServerError.InvalidSession;
  }

  return null;
}

function mapApiErrorToServerError(error: ApiError): ServerError | null {
  if (isLicenseBlockedInCRM(error)) {
    return ServerError.LicenseBlockedInCRM;
  }

  if (isLicenseExpired(error)) {
    return ServerError.LicenseExpired;
  }

  if (isRequestUnauthorized(error)) {
    return ServerError.InvalidSession;
  }

  if (isRequestSuspended(error)) {
    return ServerError.AccountSuspended;
  }

  if (isRequestWaitingForApproval(error)) {
    return ServerError.WaitingForApproval;
  }

  return null;
}

export async function handleConfigurationApiError(
  error: ConfigurationApiNormalizedError,
  isStartup: boolean,
): Promise<void> {
  const serverError = mapConfigurationApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received configuration api error', serverError);

  await handleServerError({
    error: serverError,
    errorObject: error,
    isStartup,
  });
}

export async function handleAccountsApiError(
  error: GlobalAccountsApiNormalizedError,
  isStartup: boolean,
): Promise<void> {
  const serverError = mapAccountsApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received global accounts api error', serverError);

  await handleServerError({
    error: serverError,
    errorObject: error,
    isStartup,
  });
}

export async function handleApiError(error: ApiError, isStartup: boolean): Promise<void> {
  const serverError = mapApiErrorToServerError(error) || ServerError.UnexpectedError;
  log('Received api error', serverError);

  await handleServerError({
    error: serverError,
    errorObject: error,
    isStartup,
  });
}

export async function handleLoginFailure(error: MessageErrorPayload | Error): Promise<void> {
  log('Login failure', error);
  const payload = error as MessageErrorPayload;
  const isStartup = false; // websocket login failures occur during reconnections only

  switch (payload.error?.type) {
    case 'license_expired':
      await handleServerError({
        error: ServerError.LicenseExpired,
        errorObject: error,
        isStartup,
      });

      return;

    case 'seats_limit_exceeded':
      await handleServerError({
        error: ServerError.TooManyAgents,
        errorObject: error,
        isStartup,
      });

      return;

    case 'requester_awaiting_approval':
      await handleServerError({
        error: ServerError.WaitingForApproval,
        errorObject: error,
        isStartup,
      });

      return;

    case 'requester_suspended':
      await handleServerError({
        error: ServerError.AccountSuspended,
        errorObject: error,
        isStartup,
      });

      return;

    case 'authentication':
    case 'authorization':
      if (payload.error.message === (ApiErrorMessagePart.IpNotAllowed as string)) {
        await handleServerError({
          error: ServerError.IpNotAllowed,
          errorObject: error,
          isStartup,
        });
      } else if (
        [ApiErrorMessagePart.AlreadyLoggedIn, ApiErrorMessagePart.AlreadyLoggingIn].includes(
          payload.error.message as ApiErrorMessagePart,
        )
      ) {
        // We reconnected too quickly after getting back online
        // or the restored connection did not require logging in
        log('Authorization error: Agent already logged in');
        getReconnector().loginSuccessful();
      } else if (payload.error.message === (ApiErrorMessagePart.InvalidAccessToken as string)) {
        redirectToAccounts();
      } else {
        Sentry.captureException(new Error(`Authorization error (payload=${JSON.stringify(error)})`));
        redirectToAccountsSignout(payload.error?.message as SignoutReason);
      }

      return;

    case 'pending_requests_limit_reached':
    case 'too_many_requests':
      await handleServerError({
        error: ServerError.TooManyRequests,
        errorObject: error,
        isStartup,
      });

      return;

    case 'internal':
      if (payload.error.message === (ApiErrorMessagePart.InternalServerError as string)) {
        await handleServerError({
          error: ServerError.UnexpectedError,
          errorObject: error,
          isStartup,
        });

        return;
      }

      // log to Sentry all other internal errors and redirect out
      break;

    case 'send_failed':
      log(`Login action failed to be sent`, error);

      getReconnector().loginFailed();

      // Don't redirect to accounts
      // If login failed due to disconnected websocket, we should rely on reconnector mechanism
      return;
  }

  Sentry.captureException(new Error(`Login failure unknown error (payload=${JSON.stringify(error)})`));
  redirectToAccountsSignout();
}
